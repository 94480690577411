const autoNgTemplateLoaderTemplate1 = require('./customTemplateCommonFields.html');

import CustomTemplateCommonFieldsController from './customTemplateCommonFieldsController.js';

angular.module('portainer.app').component('customTemplateCommonFields', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CustomTemplateCommonFieldsController,
  bindings: {
    formValues: '=',
  },
});
