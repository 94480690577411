const autoNgTemplateLoaderTemplate1 = require('./porImageRegistry.html');

angular.module('portainer.docker').component('porImageRegistry', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'porImageRegistryController',
  bindings: {
    model: '=', // must be of type PorImageRegistryModel
    pullWarning: '<',
    autoComplete: '<',
    labelClass: '@',
    inputClass: '@',
    onImageChange: '&',
  },
  require: {
    form: '^form',
  },
});
