const autoNgTemplateLoaderTemplate1 = require('./resourcePool.html');

angular.module('portainer.kubernetes').component('kubernetesResourcePoolView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesResourcePoolController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
