const autoNgTemplateLoaderTemplate1 = require('./kubernetesSidebarContent.html');

angular.module('portainer.kubernetes').component('kubernetesSidebarContent', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    adminAccess: '<',
    endpointId: '<',
    currentState: '<',
  },
});
