const autoNgTemplateLoaderTemplate1 = require('./applications.html');

angular.module('portainer.kubernetes').component('kubernetesApplicationsView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesApplicationsController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
