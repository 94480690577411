const autoNgTemplateLoaderTemplate1 = require('./endpointItem.html');

import angular from 'angular';

import EndpointItemController from './endpoint-item-controller';

angular.module('portainer.app').component('endpointItem', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '<',
    onSelect: '<',
    onEdit: '<',
    isAdmin: '<',
    tags: '<',
  },
  controller: EndpointItemController,
});
