const autoNgTemplateLoaderTemplate1 = require('./oauth-providers-selector.html');

angular.module('portainer.oauth').component('oauthProvidersSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    onSelect: '<',
    provider: '=',
  },
  controller: 'OAuthProviderSelectorController',
});
