const autoNgTemplateLoaderTemplate1 = require('./dockerSidebarContent.html');

angular.module('portainer.docker').component('dockerSidebarContent', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointApiVersion: '<',
    swarmManagement: '<',
    standaloneManagement: '<',
    adminAccess: '<',
    offlineMode: '<',
    toggle: '<',
    currentRouteName: '<',
    endpointId: '<',
    showStacks: '<',
  },
});
