const autoNgTemplateLoaderTemplate1 = require('./templateForm.html');

angular.module('portainer.app').component('templateForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'TemplateFormController',
  bindings: {
    model: '=',
    categories: '<',
    networks: '<',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
    showTypeSelector: '<',
  },
});
