const autoNgTemplateLoaderTemplate1 = require('./createEdgeStackView.html');

import angular from 'angular';

import { CreateEdgeStackViewController } from './createEdgeStackViewController';

angular.module('portainer.edge').component('createEdgeStackView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CreateEdgeStackViewController,
});
