const autoNgTemplateLoaderTemplate1 = require('./editEdgeStackForm.html');

import angular from 'angular';

import { EditEdgeStackFormController } from './editEdgeStackFormController';

angular.module('portainer.edge').component('editEdgeStackForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EditEdgeStackFormController,
  bindings: {
    model: '<',
    actionInProgress: '<',
    submitAction: '<',
    edgeGroups: '<',
  },
});
