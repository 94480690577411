const autoNgTemplateLoaderTemplate1 = require('./kubernetesConfigurationData.html');

angular.module('portainer.kubernetes').component('kubernetesConfigurationData', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesConfigurationDataController',
  bindings: {
    formValues: '=',
    isValid: '=',
    isCreation: '=',
  },
});
