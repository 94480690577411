const autoNgTemplateLoaderTemplate1 = require('./oauth-settings.html');

angular.module('portainer.oauth').component('oauthSettings', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    settings: '=',
    teams: '<',
  },
  controller: 'OAuthSettingsController',
});
