const autoNgTemplateLoaderTemplate1 = require('./edgeJobForm.html');

import angular from 'angular';

import { EdgeJobFormController } from './edgeJobFormController';

angular.module('portainer.edge').component('edgeJobForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeJobFormController,
  bindings: {
    model: '=',
    groups: '<',
    tags: '<',
    addLabelAction: '<',
    removeLabelAction: '<',
    formAction: '<',
    formActionLabel: '@',
    actionInProgress: '<',
  },
});
